import { ArticleSearchResultResponse, AuthenticatedUserResponse, CertificateResponse, CollectionRuleResponse, PrivateAboutPagesResponse, PublicAboutPageResponse, UserResponse } from "authory-api-types/dist/types"
import { useFormik } from "formik"
import { CollectionItemOrder, CollectionRuleType, ProfileView, UserCoverStyle, UserProfileStyle, Visibility } from "authory-api-types/dist/enums"
import { OGTags, SEO_TYPE } from "../../components/OGTags"
import { UnstyledLink } from "../../components/ReusableStyledComponents/UnstyledLink"
import { V3BodyGrey35 } from "../../components/ReusableStyledComponents/V3BodyGrey35"
import { V3PublicSizer } from "../../components/ReusableStyledComponents/V3PublicSizer"
import { APPLICATION_ROUTES } from "../../types/routes"
import { V3SubscribePopup } from "../../components/SubscribePopup"
import { V4ProfileHero } from "../../components/ProfileHero"
import { ContactUserFormik } from "../../components/V3ContactUser"
import { ProfileFilters } from "../../components/ProfileFilters"
import { VerticalSpacing } from "../../components/VerticalSpacing"
import { V3NoProfileCollections, V3NoSearchResults, V3ProfileEmptyCollection } from "../../components/V3EmptyStateScreen"
import { processQueryArgs } from "../../utils/processQueryArgs"
import { V3ProfileList } from "../../components/V3ProfileList"
import { InfinitePaginationFooter } from "../../components/InfinitePaginationFooter"
import { PCFWrapper, PublicProfileSearchItemsContentSizer, PublicProfileSearchItemsPad, V3PublicPageTeaser, V4EditCoverPos } from "./V4ProfilePublicPageLayout.styles"
import { EditableIt, EditableSortableItems, EditableSortableTheme, EditableSortableType } from "../../components/EditableSortableItems"
import { UpdateProfileFormik } from "../../components/V3EditProfilemodal"
import { DEFAULT_SEARCH_PARAMS } from "../../types/profile_search_params"
import { V3ModalSubscribed } from "../../components/V3ModalSubscribed"
import { V3ModalUnsubscribed } from "../../components/V3ModalUnsubscribed"
import Script from "next/script"
import { useState } from "react"
import { SourceInProgress } from "../../components/SourceInProgress"
import { MarketingInviteBar } from "../../components/MarketingInviteBar"
import { V3TertiaryButton } from "../../components/Button"
import { V3IconImage } from "../../components/Icons"
import { getV4ColorVariant } from "../../components/ProfileHero/V4ProfileHero.utils"
import { getCoverInfo } from "../../utils/getCoverInfo"
import { V3ProfileHeroColorVariants } from "../../components/ProfileHero/V3ProfileHero.types"
import { V3TertiaryCTAColor } from "../../components/Button/V3TertiaryCTA.types"
import { SidebarLayoutShowDesktop } from "../../layout/SideBarLayout"
import { animated, useChain, useSpring, useSpringRef } from "@react-spring/web"
import { CollectionBannerDetails } from "../../components/CollectionBannerDetails"
import { CollectionAddItemDropdown } from "../../components/EditableSortableItems/CollectionAddItemDropdown"
import { V3BlueRoundPlus } from "../../components/ReusableStyledComponents/V3BlueRoundPlus"
import { V4AboutPageContent } from "../../components/V4AboutPageContent"
import React from "react"
import { FadeInContainer } from "../../components/ReusableStyledComponents/FadeIn"

export type ProfileSearchForm = {
    collection: string,
    text: string,
    source: string,
    subAccount: string,
    type: string,
    aboutPage?: string,
}

export interface V4ProfilePublicPageLayoutProps {
    isPrivateView?: boolean,
    visitorBannerOpen?: boolean,
    author: AuthenticatedUserResponse | UserResponse,
    queryArgs: Record<string, string>,
    onSubscribeSumit: (email: string) => Promise<void>,
    subscribeOpen?: boolean,
    certificates?: CertificateResponse[],
    onContactUserSubmit: (data: ContactUserFormik) => Promise<void>,
    items: ArticleSearchResultResponse[],
    itemsLoading: boolean,
    itemsHref: string,
    customDomainSearchTriggered?: boolean,
    contentCanFetchmore: boolean,
    subscribedModalOpen?: boolean,
    unsubscribedModalOpen?: boolean,
    initialContactOpen?: boolean,
    contentLoadmore: () => void,
    onSocialsEditableClick?: () => void,
    onContactEditableClick?: () => void,
    onCoverEditableClick?: () => void,
    onPIEditableClick?: () => void,
    onProfileFilterEditableClick?: () => void,
    onEditProfileModalSubmit?: (data: UpdateProfileFormik) => void,
    onSearchFormSubmit?: (e: ProfileSearchForm) => void,
    onCollectionStopDrag?: (data: Record<string, number>) => void,
    onCollectionSubmitName?: (name: string, slug?: string) => Promise<any>,
    onCollectionAddToProfile?: (slug: string) => void,
    onCollectionRemoveFromProfile?: (slug: string) => void,
    onCollectionUpdateRules?: (slug: string) => void,
    onAboutPageSubmitName?: (name: string, slug?: string) => Promise<any>,
    onAboutPageStopDrag?: (data: Record<string, number>) => void,
    onAboutPageDelete?: (id: string) => void,
    onAboutPageSubmitTitle?: (title: string, id: string) => Promise<any>,
    onTemporaryImageUploadHandler?: (file: File) => Promise<string>,
    onAboutPageSubmitContent?: (html: string, id: string) => Promise<void>,
    rules: CollectionRuleResponse[],
    isAuthenticatedWithInactiveProfile?: boolean,
    legacyEditingExperience?: boolean,
    signupSkeleton?: boolean,
    skipCookieYes?: boolean,
    animationDisabled?: boolean,
    userAboutPage?: PublicAboutPageResponse | PrivateAboutPagesResponse,
    isAboutPage?: boolean,
    aboutPageLoading?: boolean,
}

export const V4ProfilePublicPageLayout = ({
    author,
    queryArgs,
    isPrivateView = false,
    visitorBannerOpen = false,
    onSubscribeSumit,
    subscribeOpen = false,
    certificates,
    onContactUserSubmit,
    items,
    itemsLoading,
    itemsHref,
    customDomainSearchTriggered = false,
    subscribedModalOpen = false,
    unsubscribedModalOpen = false,
    initialContactOpen = false,
    contentCanFetchmore,
    rules,
    contentLoadmore,
    onSocialsEditableClick,
    onContactEditableClick,
    onCoverEditableClick,
    onPIEditableClick,
    onProfileFilterEditableClick,
    onEditProfileModalSubmit,
    onSearchFormSubmit,
    onCollectionStopDrag,
    onCollectionSubmitName,
    onCollectionAddToProfile,
    onCollectionRemoveFromProfile,
    onCollectionUpdateRules,
    onAboutPageSubmitName,
    onAboutPageStopDrag,
    onAboutPageDelete,
    onTemporaryImageUploadHandler,
    onAboutPageSubmitContent,
    onAboutPageSubmitTitle,
    isAuthenticatedWithInactiveProfile,
    legacyEditingExperience,
    signupSkeleton = false,
    skipCookieYes = false,
    animationDisabled = true,
    userAboutPage,
    isAboutPage = false,
}: V4ProfilePublicPageLayoutProps) => {
    const { searchArgs, profileCollections } = processQueryArgs(queryArgs, author);
    const [subscribeCompletedModal, setSubscribeCompletedModal] = useState(subscribedModalOpen);
    const [unsubscribedModal, setUnsubscribedModal] = useState(unsubscribedModalOpen);
    const [subscribeModalOpen, setSubscribeModalOpen] = useState(subscribeOpen);

    const formik = useFormik<ProfileSearchForm>({
        initialValues: {
            collection: searchArgs?.collection || "",
            text: searchArgs?.text || DEFAULT_SEARCH_PARAMS.TEXT,
            source: searchArgs?.source || DEFAULT_SEARCH_PARAMS.SOURCE,
            subAccount: searchArgs?.subAccount || DEFAULT_SEARCH_PARAMS.SUBACCOUNT,
            type: searchArgs?.type || DEFAULT_SEARCH_PARAMS.TYPE,
            aboutPage: searchArgs?.aboutPage || DEFAULT_SEARCH_PARAMS.ABOUTPAGE
        },
        enableReinitialize: true,
        onSubmit: (data) => {
            const formikCollection = author.collections.find(col => col.canonicalSlug === data?.collection);

            onSearchFormSubmit && onSearchFormSubmit({
                ...data,
                collection: formikCollection?.slug || data.collection,
            });
        }
    });

    const hasItems = !!items.reduce((acc, curr) => acc + curr.articles.length, 0);
    const profileHasCollectionsAdded = !!profileCollections.length;
    const profileHasCollections = !!author.collections.length;
    const collectionTarget = profileCollections.find(col => col.canonicalSlug === searchArgs?.collection);
    const collectionIsEmpty = collectionTarget && !collectionTarget.publicationCount;

    const srcInProgress = (collectionTarget?.default ? author.sources.sources : author.sources.sources
        .filter(src => (rules.find(rule => rule.type === CollectionRuleType.Source)?.params || []).find(it => it === src?.slug))
    ).filter(src => src.inProgress);

    // Background and colors
    const { color } = getCoverInfo(author);
    const colorVariant = getV4ColorVariant(color, author.coverStyle as UserCoverStyle);

    const showFilters = isPrivateView || author.profileFilterEnabled;
    const showCollections = isPrivateView || profileCollections.length > 1;

    const HeroSpringRef = useSpringRef()
    const [HegoAnimationProps] = useSpring(
        () => ({
            ref: HeroSpringRef,
            from: { opacity: 0 },
            to: { opacity: 1 },
            config: {
                duration: 1200,
            },
            immediate: animationDisabled,
        }),
        []
    );

    const FiltersSpringRef = useSpringRef()
    const [FilterAnimationProps] = useSpring(
        () => ({
            ref: FiltersSpringRef,
            from: { opacity: 0 },
            to: { opacity: 1 },
            config: {
                duration: 1200,
            },
            immediate: animationDisabled,
        }),
        []
    );

    const timings = animationDisabled ? undefined : [9, 10];

    useChain([HeroSpringRef, FiltersSpringRef], timings);

    const privateCollections = author.collections.filter(col => col.visibility !== Visibility.Public);

    const AnimationToUse = isPrivateView ? React.Fragment : FadeInContainer;
    const propsToUse = isPrivateView ? {} : { $customDuration: "600ms" }

    return <div>
        <V3PublicSizer isPrivateView={isPrivateView} verticalSpace={false} signupSkeleton={signupSkeleton}>
            {visitorBannerOpen && <V3PublicPageTeaser>
                <V3BodyGrey35>Hey! You're viewing this profile as a visitor! Why don't you <UnstyledLink target="_blank" href={APPLICATION_ROUTES.SIGNUP} style={{ textDecoration: "underline" }}>create your own</UnstyledLink>?</V3BodyGrey35>
            </V3PublicPageTeaser>}
            {
                isAuthenticatedWithInactiveProfile && <MarketingInviteBar
                    customMessage="Only you can see your portfolio right now. Publish it to make your portfolio publicly available at this URL."
                />
            }
            {author && !isPrivateView && <OGTags user={author} target={SEO_TYPE.PROFILE} skipCookieYes={skipCookieYes} />}
            <animated.div style={HegoAnimationProps}>
                <V4ProfileHero
                    author={author}
                    onContactUserSubmit={onContactUserSubmit}
                    editMode={isPrivateView}
                    certificates={certificates}
                    onSocialsEditableClick={onSocialsEditableClick}
                    onContactEditableClick={onContactEditableClick}
                    onPIEditableClick={onPIEditableClick}
                    onEditProfileModalSubmit={onEditProfileModalSubmit}
                    initialContactOpen={initialContactOpen}
                    openSubscribeModal={() => setSubscribeModalOpen(true)}
                    legacyEditingExperience={legacyEditingExperience || false}
                    onAboutPageSubmitName={onAboutPageSubmitName}
                    onAboutPageStopDrag={onAboutPageStopDrag}
                    onAboutPageDelete={onAboutPageDelete}
                    onAboutPageSubmitTitle={onAboutPageSubmitTitle}
                    aboutPageTitle={userAboutPage?.title}
                    onAboutPageSelect={(id) => {
                        if (isPrivateView) {
                            formik.setFieldValue("aboutPage", id);
                            formik.submitForm();
                        }
                    }}
                    aboutPageId={queryArgs.aboutPage || userAboutPage?.slug}
                    isAboutPage={isAboutPage}
                />
            </animated.div>
            <animated.div style={FilterAnimationProps}>
                <PublicProfileSearchItemsPad>

                    <PublicProfileSearchItemsContentSizer
                        $viewType={author.defaultView as ProfileView}
                        $profileStyle={UserProfileStyle.leftAligned}
                        $isAboutPage={isAboutPage}
                    >
                        <AnimationToUse {...propsToUse}>
                            {
                                (isAboutPage) ? <V4AboutPageContent
                                    isPrivateView={isPrivateView}
                                    userAboutPage={userAboutPage}
                                    queryArgs={queryArgs}
                                    onTemporaryImageUploadHandler={onTemporaryImageUploadHandler}
                                    onAboutPageSubmitContent={onAboutPageSubmitContent}
                                /> : <>
                                    {isPrivateView && <SidebarLayoutShowDesktop>
                                        <V4EditCoverPos>
                                            <V3TertiaryButton
                                                ctaColor={colorVariant === V3ProfileHeroColorVariants.light ? V3TertiaryCTAColor.white : V3TertiaryCTAColor.gray}
                                                onClick={onCoverEditableClick}
                                                icon={<V3IconImage stroke={colorVariant === V3ProfileHeroColorVariants.light ? V3TertiaryCTAColor.white : undefined} />}
                                                text="Edit Cover"
                                            />
                                        </V4EditCoverPos>
                                    </SidebarLayoutShowDesktop>}
                                    <VerticalSpacing bottom={showCollections || showFilters ? 32 : 12} style={{ position: "relative", zIndex: 1 }} >
                                        <PCFWrapper $sideMargins={showCollections || showFilters}>
                                            {
                                                showCollections && <div >
                                                    <EditableSortableItems
                                                        colorVariant={EditableSortableTheme.Dark}
                                                        its={profileCollections.map(coll => ({ id: coll.canonicalSlug, label: coll.label, hasRules: !!coll.ruleCount, isDefault: coll.default }))}
                                                        editMode={isPrivateView}
                                                        activeCol={formik.values.collection}
                                                        onItemSelect={(slug) => {
                                                            if (slug === formik.values.collection) return;
                                                            formik.setFieldValue("collection", author.collections.find(coll => coll.canonicalSlug === slug)?.slug);
                                                            formik.submitForm();
                                                        }}
                                                        onCollectionStopDrag={onCollectionStopDrag}
                                                        onCollectionSubmitName={onCollectionSubmitName}
                                                        onCollectionRemoveFromProfile={onCollectionRemoveFromProfile}
                                                        onCollectionUpdateRules={onCollectionUpdateRules}
                                                        renderShortcutAddItem={(scrolling, onNew, onOpenChange, simplebarScroll) => {
                                                            return <CollectionAddItemDropdown
                                                                customOffset={{ crossAxis: -6, mainAxis: 0 }}
                                                                onNew={onNew}
                                                                onCollectionAddToProfile={onCollectionAddToProfile}
                                                                collections={privateCollections}
                                                                scrolling={scrolling}
                                                                onOpenChange={onOpenChange}
                                                                scrollSimpleBar={simplebarScroll}
                                                            >
                                                                <div style={{ padding: 18, display: "flex" }}>
                                                                    <V3BlueRoundPlus />
                                                                </div>
                                                            </CollectionAddItemDropdown>
                                                        }}
                                                        renderMainAddItem={(scrolling, onNew, onOpenChange, simplebarScroll, setEditingComponentID, isEditing, itemsLength) => {
                                                            return <CollectionAddItemDropdown
                                                                customOffset={{ crossAxis: -6, mainAxis: 0 }}
                                                                onNew={onNew}
                                                                onCollectionAddToProfile={onCollectionAddToProfile}
                                                                collections={privateCollections}
                                                                scrolling={scrolling}
                                                                onOpenChange={onOpenChange}
                                                                scrollSimpleBar={simplebarScroll}
                                                                isEditing={isEditing}
                                                            >
                                                                <EditableIt
                                                                    editMode
                                                                    activeCol={""}
                                                                    onSelectDrag={() => { }}
                                                                    onItemSelect={() => { }}
                                                                    onCollectionSubmitName={onCollectionSubmitName}
                                                                    setEditingComponentID={setEditingComponentID}
                                                                    isEditing={isEditing}
                                                                    totalItemsNumber={itemsLength}
                                                                    addText={!privateCollections.length ? "Create collection" : "Add collection"}
                                                                    itemType={EditableSortableType.Collection}
                                                                    colorVariant={EditableSortableTheme.Dark}
                                                                />
                                                            </CollectionAddItemDropdown>
                                                        }}
                                                    />
                                                </div>
                                            }
                                            {
                                                showFilters && <div>
                                                    <ProfileFilters
                                                        editMode={isPrivateView}
                                                        author={author}
                                                        onProfileFilterEditableClick={onProfileFilterEditableClick}
                                                        formik={formik}
                                                        extendedMode={!isPrivateView && profileCollections.length <= 1}
                                                    />
                                                </div>
                                            }
                                        </PCFWrapper>
                                    </VerticalSpacing>
                                    {
                                        isPrivateView && <>
                                            <CollectionBannerDetails
                                                isManuallyOrdered={collectionTarget?.itemOrder === CollectionItemOrder.Manual}
                                                isAutoUpdating={!!collectionTarget?.autoUpdate}
                                                hasFiltersEnabled={!!collectionTarget?.ruleCount}
                                                collectionSlug={collectionTarget?.canonicalSlug}
                                                onCollectionUpdateRules={() => onCollectionUpdateRules && collectionTarget && onCollectionUpdateRules(collectionTarget.canonicalSlug)}
                                            />
                                            {
                                                !!srcInProgress.length && <VerticalSpacing bottom={12}>
                                                    <SourceInProgress
                                                        sources={srcInProgress}
                                                    />
                                                </VerticalSpacing>
                                            }
                                        </>
                                    }
                                    <VerticalSpacing top={48}>
                                        {
                                            collectionIsEmpty ? <V3ProfileEmptyCollection isPrivate={isPrivateView} isDefaultCollection={collectionTarget.default} collectionName={collectionTarget.label} /> : <>
                                                {
                                                    profileHasCollectionsAdded ? <V3ProfileList
                                                        editMode={isPrivateView}
                                                        items={items}
                                                        viewType={author.defaultView as ProfileView}
                                                        itemsLoading={itemsLoading}
                                                        itemsHref={itemsHref}
                                                        isPrivateView={isPrivateView}
                                                        pinnedItem={!!items.length ? items[0].pinned : undefined}
                                                        collection={isPrivateView || collectionTarget?.overrideContentVisibility === Visibility.Public ? collectionTarget : undefined}
                                                        customDomainSearchTriggered={customDomainSearchTriggered}
                                                        signupSkeleton={signupSkeleton}
                                                    /> : <div style={{ minHeight: 400 }}>
                                                        <V3NoProfileCollections
                                                            hasCollectionToAdd={profileHasCollections}
                                                            isPrivate={isPrivateView}
                                                        />
                                                    </div>
                                                }
                                                {
                                                    !itemsLoading && !hasItems && !signupSkeleton && !collectionIsEmpty && profileHasCollectionsAdded && <V3NoSearchResults onClearHandler={() => {
                                                        formik.setFieldValue("text", DEFAULT_SEARCH_PARAMS.TEXT);
                                                        formik.setFieldValue("type", DEFAULT_SEARCH_PARAMS.TYPE);
                                                        formik.setFieldValue("collection", searchArgs?.collection);
                                                        formik.setFieldValue("source", DEFAULT_SEARCH_PARAMS.SOURCE);
                                                        formik.setFieldValue("subAccount", DEFAULT_SEARCH_PARAMS.SUBACCOUNT);
                                                        formik.submitForm();
                                                    }} />
                                                }
                                            </>
                                        }
                                    </VerticalSpacing>
                                </>
                            }
                        </AnimationToUse>
                    </PublicProfileSearchItemsContentSizer>
                    {
                        !isAboutPage && <InfinitePaginationFooter
                            contentLoading={itemsLoading}
                            numPagesLoaded={items.length}
                            contentCanFetchmore={contentCanFetchmore}
                            loadMore={contentLoadmore}
                        />
                    }
                </PublicProfileSearchItemsPad>
            </animated.div>
            {
                subscribeModalOpen && <V3SubscribePopup
                    firstName={author.firstName}
                    name={`${author.firstName} ${author.lastName}`}
                    onSubscribeSumit={onSubscribeSumit}
                    hasPrivacyPolicy={false}
                    slug={author.slug}
                    disabled={!author.displayEmailSubscribe}
                    onCloseHandler={setSubscribeModalOpen}
                />
            }
            {
                subscribeCompletedModal && <V3ModalSubscribed
                    modalOpen={subscribeCompletedModal}
                    onCloseHandler={() => setSubscribeCompletedModal(false)}
                    author={author}
                />
            }
            {
                unsubscribedModal && <V3ModalUnsubscribed
                    modalOpen={unsubscribedModal}
                    onCloseHandler={() => setUnsubscribedModal(false)}
                />
            }
            {
                !isPrivateView && <Script src="https://www.google.com/recaptcha/api.js?render=explicit" strategy="lazyOnload" />
            }
        </V3PublicSizer>
    </div>
}
